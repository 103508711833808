import { createSelector } from 'reselect';
import { RootState } from '../index';

const selectFromPickupCargoPlacesState = (state: RootState) => state.fromPickupCargoPlacesState;

export const selectFromPickupCargoPlaces = createSelector(
  [selectFromPickupCargoPlacesState],
  (fromPickupCargoPlacesState) => fromPickupCargoPlacesState.fromPickupCargoPlaces
);

export const selectTotalRowFromPickupCargoPlaces = createSelector(
  [selectFromPickupCargoPlacesState],
  (fromPickupCargoPlacesState) => fromPickupCargoPlacesState.totalRows
);

export const selectWaitFromPickupCargoPlacesTableFetch = createSelector(
  [selectFromPickupCargoPlacesState],
  (fromPickupCargoPlacesState) => fromPickupCargoPlacesState.waitTableFetch
);
