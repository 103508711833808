import { createSelector } from 'reselect';
import { RootState } from '../index';

const selectFromPickupShipmentsState = (state: RootState) => state.fromPickupShipmentsState;

export const selectFromPickupShipments = createSelector(
  [selectFromPickupShipmentsState],
  (fromPickupShipmentsState) => fromPickupShipmentsState.fromPickupShipments
);

export const selectTotalRowFromPickupShipments = createSelector(
  [selectFromPickupShipmentsState],
  (fromPickupShipmentsState) => fromPickupShipmentsState.totalRows
);

export const selectWaitFromPickupShipmentsTableFetch = createSelector(
  [selectFromPickupShipmentsState],
  (fromPickupShipmentsState) => fromPickupShipmentsState.waitTableFetch
);
