import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { GridColDef, GridFilterItem, GridPaginationModel, GridSortItem } from '@mui/x-data-grid-pro';
import { SharedTable } from '../../components/sharedTable';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import {
  selectFromPickupShipments,
  selectTotalRowFromPickupShipments,
  selectWaitFromPickupShipmentsTableFetch,
} from '../../store/selectors/fromPickupShipmentsSelector';
import { getStoredItem, storeItem } from '../../services/storageService';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';
import { useLocation } from 'react-router-dom';
import { IOzonLogItem } from '../../models/OzonLogItem';
import { getFromPickupShipments } from '../../store/slices/fromPickupShipmentsSlice';

const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };

export const FromPickupShipmentsPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const entityId = searchParams.get('id');

  const filterById = entityId
    ? [
        {
          id: 'by_id',
          field: 'id',
          operator: '=',
          value: parseInt(entityId, 10),
        },
      ]
    : null;

  const tableName = 'fromPickupShipments' + (entityId ? 'OneRow' : '');

  const dispatch = useAppDispatch();

  const fromPickupShipments = useSelector(selectFromPickupShipments);
  const isLoading = useSelector(selectWaitFromPickupShipmentsTableFetch);
  const totalRows = useSelector(selectTotalRowFromPickupShipments);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedFromPickupShipmentsIds, setSelectedFromPickupShipmentsIds] = useState<number[]>([]);

  let columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'tms_order_id',
      headerName: 'ТМС',
      type: 'number',
    },
    {
      field: 'airway_bill_number',
      headerName: 'Номер по ГАН',
      type: 'string',
    },
    {
      field: 'created_at',
      headerName: 'Создана',
      type: 'dateTime',
    },
    {
      field: 'updated_at',
      headerName: 'Обновлена',
      type: 'dateTime',
    },
    {
      field: 'sent_at',
      headerName: 'Отправлена',
      type: 'dateTime',
    },
    {
      field: 'start_point_name',
      headerName: 'Место отправления',
      type: 'string',
    },
    {
      field: 'start_point_region',
      headerName: 'Регион отправления',
      type: 'string',
    },
    {
      field: 'start_point_city',
      headerName: 'Город отправления',
      type: 'string',
    },
    {
      field: 'start_point_settlement',
      headerName: 'Населенный пункт отправления',
      type: 'string',
    },
    {
      field: 'start_point_index',
      headerName: 'Индекс отправления',
      type: 'string',
    },
    {
      field: 'end_point_name',
      headerName: 'Место назначения',
      type: 'string',
    },
    {
      field: 'end_point_region',
      headerName: 'Регион назначения',
      type: 'string',
    },
    {
      field: 'end_point_city',
      headerName: 'Город назначения',
      type: 'string',
    },
    {
      field: 'end_point_settlement',
      headerName: 'Населенный пункт назначения',
      type: 'string',
    },
    {
      field: 'end_point_index',
      headerName: 'Индекс назначения',
      type: 'string',
    },
    {
      field: 'ozon_logs',
      headerName: 'Логи Озон',
      type: 'string',
      renderCell: (params) => {
        if (params.value !== null) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
              <Tooltip
                title={
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {params.value.map((log: IOzonLogItem) => `${log.message} (${log.timestamp})`).join('\n')}
                  </span>
                }
              >
                <InfoIcon color="error" style={{ marginLeft: 5, cursor: 'pointer', alignSelf: 'center' }} />
              </Tooltip>
            </div>
          );
        }
        return null;
      },
    },
  ];

  if (filterById) {
    columns = columns.map((x) => {
      x.filterable == false;
      x.sortable == false;
      return x;
    });
  }

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedFromPickupShipmentsIds(pageSelectedRowIds);
      storeItem('selectedOn' + tableName, pageSelectedRowIds);
    },
    [fromPickupShipments, selectedFromPickupShipmentsIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsPaginationChange(true);
    setIsDataNededToBeFetched(true);
  }, []);

  const dispatchGetFromPickupShipments = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getFromPickupShipments({
        filtering: filterById ? filterById : [snapshotState?.filter?.filterModel?.items ?? defaultFilter].flat(),
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getFromPickupShipments.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>('selectedOn' + tableName);
      if (storedSelectedRowIds) {
        setSelectedFromPickupShipmentsIds(storedSelectedRowIds);
      }
    }
  }, [isDataNededToBeFetched]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetFromPickupShipments();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <SharedTable
        columns={columns}
        loading={isLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={fromPickupShipments}
        selectedRowIds={selectedFromPickupShipmentsIds}
        tableName={tableName}
      />
    </div>
  );
};
