import api from './apiClient';

export const fetchFromGanShipments = async (payload?: any) => {
  const response = await api.post('/airwaybills/table', payload);
  return response.data;
};

export const requestFromGanShipmentsPackAction = (payload: any) => {
  return api.post('/airwaybills/register', payload);
};

export const requestFromGanShipmentsSendAction = (payload: any) => {
  return api.post('/airwaybills/tofirstmile', payload);
};
