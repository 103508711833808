import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFromPickupCargoPlace } from '../../models/FromPickupCargoPlace';
import { fetchFromPickupCargoPlaces } from '../../services/fromPickupCargoPlacesService';

export interface IFromPickupCargoPlacesState {
  fromPickupCargoPlaces: IFromPickupCargoPlace[];
  totalRows: number;

  waitTableFetch: boolean;
  tableFetchError: string | null;
}

const initialState: IFromPickupCargoPlacesState = {
  fromPickupCargoPlaces: [],
  totalRows: 0,
  waitTableFetch: false,
  tableFetchError: null,
};

export const getFromPickupCargoPlaces = createAsyncThunk('fromPickupCargoPlaces/table', async (payload: any, thunkAPI) => {
  try {
    const res = await fetchFromPickupCargoPlaces(payload);
    return res;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to fetch cargo places from pickup');
  }
});

const fromPickupCargoPlacesSlice = createSlice({
  name: 'fromPickupCargoPlaces',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFromPickupCargoPlaces.pending, (state) => {
      state.waitTableFetch = true;
    });
    builder.addCase(getFromPickupCargoPlaces.fulfilled, (state, action) => {
      const res = action.payload;
      state.waitTableFetch = false;
      state.fromPickupCargoPlaces = res.rows;
      state.totalRows = res.totalRows;
    });
    builder.addCase(getFromPickupCargoPlaces.rejected, (state, action) => {
      state.waitTableFetch = false;
      state.tableFetchError = action.payload as string;
    });
  },
});

export default fromPickupCargoPlacesSlice.reducer;
