import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFromGanShipment } from '../../models/FromGanShipment';
import { fetchFromGanShipments, requestFromGanShipmentsPackAction, requestFromGanShipmentsSendAction } from '../../services/fromGanShipmentsService';

export type FromGanShipmentsServerErrorResponseType = {
  message: string;
};

export interface IFromGanShipmentsState {
  fromGanShipments: IFromGanShipment[];
  totalRows: number;

  waitTableFetch: boolean;
  tableFetchError: string | null;

  waitPacking: boolean;
  packingError: FromGanShipmentsServerErrorResponseType | null;

  waitSending: boolean;
  sendingError: FromGanShipmentsServerErrorResponseType | null;
}

const initialState: IFromGanShipmentsState = {
  fromGanShipments: [],
  totalRows: 0,

  waitTableFetch: false,
  tableFetchError: null,

  waitPacking: false,
  packingError: null,

  waitSending: false,
  sendingError: null,
};

export const getFromGanShipments = createAsyncThunk('fromGanShipments/table', async (payload: any, thunkAPI) => {
  try {
    const response = await fetchFromGanShipments(payload);
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to fetch shipments from GAN');
  }
});

export const packFromGanShipments = createAsyncThunk('fromGanShipments/pack', async (payload: any, thunkAPI) => {
  try {
    const response = await requestFromGanShipmentsPackAction(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to pack shipments from gan');
  }
});

export const sendFromGanShipments = createAsyncThunk('fromGanShipments/send', async (payload: any, thunkAPI) => {
  try {
    const response = await requestFromGanShipmentsSendAction(payload);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to send shipments from gan');
  }
});

const fromGanShipmentsSlice = createSlice({
  name: 'fromGanShipments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFromGanShipments.pending, (state) => {
      state.waitTableFetch = true;
    });
    builder.addCase(getFromGanShipments.fulfilled, (state, action) => {
      const response = action.payload;
      state.waitTableFetch = false;
      state.fromGanShipments = response.rows;
      state.totalRows = response.totalRows;
    });
    builder.addCase(getFromGanShipments.rejected, (state, action) => {
      state.waitTableFetch = false;
      state.tableFetchError = action.payload as string;
    });

    builder.addCase(packFromGanShipments.pending, (state) => {
      state.waitPacking = true;
    });
    builder.addCase(packFromGanShipments.fulfilled, (state) => {
      state.waitPacking = false;
    });
    builder.addCase(packFromGanShipments.rejected, (state, action) => {
      state.waitPacking = false;
      state.packingError = action.payload as FromGanShipmentsServerErrorResponseType;
    });

    builder.addCase(sendFromGanShipments.pending, (state) => {
      state.waitSending = true;
    });
    builder.addCase(sendFromGanShipments.fulfilled, (state) => {
      state.waitSending = false;
    });
    builder.addCase(sendFromGanShipments.rejected, (state, action) => {
      state.waitSending = false;
      state.sendingError = action.payload as FromGanShipmentsServerErrorResponseType;
    });
  },
});

export default fromGanShipmentsSlice.reducer;
export const fromGanShipmentsActions = fromGanShipmentsSlice.actions;
