export const getCurrentDate = () => {
  const currentDate = new Date();

  const formattedDate = currentDate.toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

  return formattedDate;
};
