import api from './apiClient';

export const fetchOutbounds = async (payload?: any) => {
  const response = await api.post('/outbounds/table', payload);
  return response.data;
};

export const requestOutboundsMeasure = (payload: any) => {
  return api.post('/outbounds/measure', payload);
};

export const requestOutboundsSentAction = (payload: any) => {
  return api.post('/outbounds/send', payload);
};

export const requestOutboundsSetCargoDateAction = (payload: any) => {
  return api.post('/outbounds/cargo', payload);
};

export const requestOutboundsDeleteActsAction = (payload: any) => {
  return api.post('/outbounds/delete_acts', payload);
};

export const requestOutboundsPackAction = (payload: any) => {
  return api.post('/outbounds/pack', payload);
};

export const requestReturnshipsSentAction = (payload: any) => {
  return api.post('/outbounds/sendback', payload);
};
