import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IFromPickupShipment } from '../../models/FromPickupShipment';
import { fetchFromPickupShimpents } from '../../services/fromPickupShipmentsService';

export interface IFromPickupShipmentsState {
  fromPickupShipments: IFromPickupShipment[];
  totalRows: number;

  waitTableFetch: boolean;
  tableFetchError: string | null;
}

const initialState: IFromPickupShipmentsState = {
  fromPickupShipments: [],
  totalRows: 0,
  waitTableFetch: false,
  tableFetchError: null,
};

export const getFromPickupShipments = createAsyncThunk('fromPickupShipments/table', async (payload: any, thunkAPI) => {
  try {
    const res = await fetchFromPickupShimpents(payload);
    return res;
  } catch (err) {
    return thunkAPI.rejectWithValue('Failed to fetch shipments from pickup');
  }
});

const fromPickupShipmentsSlice = createSlice({
  name: 'fromPickupShipments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFromPickupShipments.pending, (state) => {
      state.waitTableFetch = true;
    });
    builder.addCase(getFromPickupShipments.fulfilled, (state, action) => {
      const res = action.payload;
      state.waitTableFetch = false;
      state.fromPickupShipments = res.rows;
      state.totalRows = res.totalRows;
    });
    builder.addCase(getFromPickupShipments.rejected, (state, action) => {
      state.waitTableFetch = false;
      state.tableFetchError = action.payload as string;
    });
  },
});

export default fromPickupShipmentsSlice.reducer;
