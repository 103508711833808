import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Input } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { GridColDef, GridFilterItem, GridPaginationModel, GridSortItem } from '@mui/x-data-grid-pro';
import { SharedTable } from '../../components/sharedTable';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import {
  selectOutbounds,
  selectTotalRowOutbounds,
  selectWaitOutboundPack,
  selectWaitOutboundSend,
  selectWaitOutboundsTableFetch,
} from '../../store/selectors/outboundsSelector';
import { getOutbounds, packOutbounds, returnshipsServerErrorResponseType, sendReturnships } from '../../store/slices/outboundsSlice';
import { getStoredItem, removeStoredItem, storeItem } from '../../services/storageService';
import { getStateSnapshotFromLocalStorage } from '../../utils/state';
import { useLocation } from 'react-router-dom';
import { RedirectCell } from '../shared';

const defaultFilter: GridFilterItem[] = [];
const defaultSorting: GridSortItem[] = [{ field: 'id', sort: 'desc' }];
const defaultPagination: Partial<GridPaginationModel> = { page: 0, pageSize: 25 };
const hiddenFiltering: GridFilterItem[] = [
  {
    id: 'cargoTypeNotSendback',
    field: 'cargo_type',
    operator: 'is',
    value: 'sendback',
  },
];

export const ReturnShipsPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const entityId = searchParams.get('id');
  const filterById = entityId
    ? [
        {
          id: 'by_id',
          field: 'id',
          operator: '=',
          value: parseInt(entityId, 10),
        },
      ]
    : null;

  const tableName = 'returnships' + (entityId ? 'OneRow' : '');

  const dispatch = useAppDispatch();

  const outbounds = useSelector(selectOutbounds);
  const isLoading = useSelector(selectWaitOutboundsTableFetch);
  const waitPack = useSelector(selectWaitOutboundPack);
  const waitSend = useSelector(selectWaitOutboundSend);
  const totalRows = useSelector(selectTotalRowOutbounds);

  const [isDataNededToBeFetched, setIsDataNededToBeFetched] = useState(true);
  const [isPaginationChange, setIsPaginationChange] = useState(false);

  const [selectedOutboundIds, setSelectedOutboundIds] = useState<number[]>([]);

  const [tmsnumber, setTMSNumber] = useState<string>('');

  let columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      hideable: false,
    },
    {
      field: 'inbound_id',
      headerName: 'Входящая поставка',
      type: 'number',
      renderCell: (params) => <RedirectCell url={`/inbounds?id=${params.row.inbound_id}`} value={params.value} />,
    },
    {
      field: 'created_at',
      headerName: 'Создано',
      type: 'dateTime',
    },
    {
      field: 'updated_at',
      headerName: 'Обновлено',
      type: 'dateTime',
    },
    {
      field: 'postings_count',
      headerName: 'Отправлений',
      type: 'number',
      renderCell: (params) => {
        return <RedirectCell url={`/postings-to-return?entity=outbound&id=${params.row.id}`} value={params.value} />;
      },
    },
    {
      field: 'packed_at',
      headerName: 'Упаковано',
      type: 'dateTime',
    },
    {
      field: 'tms_number',
      headerName: 'ТМС',
      type: 'number',
    },
    {
      field: 'sent_at',
      headerName: 'Отправлено',
      type: 'dateTime',
    },
  ];

  if (filterById) {
    columns = columns.map((x) => {
      x.filterable == false;
      x.sortable == false;
      return x;
    });
  }

  const handleSelectionChange = useCallback(
    (pageSelectedRowIds: number[]) => {
      if (isPaginationChange) {
        setIsPaginationChange(false);
        return;
      }

      setSelectedOutboundIds(pageSelectedRowIds);
      storeItem('selectedOn' + tableName, pageSelectedRowIds);
    },
    [outbounds, selectedOutboundIds, isPaginationChange]
  );

  const handlePaginationChange = useCallback(() => {
    setIsPaginationChange(true);
    setIsDataNededToBeFetched(true);
  }, []);

  function clearSelectedOutbounds() {
    removeStoredItem('selectedOn' + tableName);
    setSelectedOutboundIds([]);
    setTMSNumber('');
  }

  const handleTMSNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTMSNumber(event.target.value);
  };

  const handlePack = useCallback(async () => {
    const outbounds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(packOutbounds(outbounds));

    if (packOutbounds.fulfilled.match(resultAction)) {
      toast.success(resultAction.payload['message:']);
      clearSelectedOutbounds();
    }
  }, [dispatch, selectedOutboundIds]);

  const handleSend = useCallback(async () => {
    const wrappedIds = selectedOutboundIds.map((id) => ({ id }));
    const resultAction = await dispatch(sendReturnships({ outbounds: wrappedIds, tms_number: Number(tmsnumber) }));

    if (sendReturnships.fulfilled.match(resultAction)) {
      console.log(resultAction);
      const result = resultAction.payload;
      toast(
        <div>
          <div>
            <b>{result.message}</b>
          </div>
          <br />
          <div>TMS ID записан ({result.updated_count})</div>
          {result.details.updated.map((item: any) => (
            <div key={item}>
              <i>- {item}</i>
            </div>
          ))}
          <br />
          <div>Ранее записаны ({result.already_registered_count})</div>
          {result.details.already_registered.map((item: any) => (
            <div key={item}>
              <i>- {item}</i>
            </div>
          ))}
        </div>,
        { autoClose: 10000 }
      );
      clearSelectedOutbounds();
      dispatchGetOutbounds();
    } else if (sendReturnships.rejected.match(resultAction)) {
      const resultError = resultAction.payload as returnshipsServerErrorResponseType;
      toast.error(resultError.message);
    }
  }, [dispatch, selectedOutboundIds, tmsnumber]);

  const dispatchGetOutbounds = useCallback(async () => {
    const snapshotState = await getStateSnapshotFromLocalStorage(tableName);
    const resultAction = await dispatch(
      getOutbounds({
        filtering: filterById ? filterById : [snapshotState?.filter?.filterModel?.items ?? defaultFilter, hiddenFiltering].flat(),
        sorting: snapshotState?.sorting?.sortModel ?? defaultSorting,
        ...(snapshotState?.pagination?.paginationModel ?? defaultPagination),
      })
    );

    if (getOutbounds.fulfilled.match(resultAction)) {
      setIsDataNededToBeFetched(false);
      const storedSelectedRowIds = getStoredItem<number[]>('selectedOn' + tableName);
      if (storedSelectedRowIds) {
        setSelectedOutboundIds(storedSelectedRowIds);
      }
    }
  }, [isDataNededToBeFetched]);

  const handleDataQueryChange = useCallback(() => {
    setIsDataNededToBeFetched(true);
  }, []);

  useEffect(() => {
    if (isDataNededToBeFetched) {
      dispatchGetOutbounds();
    }
  }, [isDataNededToBeFetched]);

  return (
    <div>
      <ToastContainer />
      {selectedOutboundIds.length > 0 && (
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Button
            variant="contained"
            color="info"
            onClick={handlePack}
            disabled={waitPack}
            startIcon={waitPack ? <CircularProgress size={16} /> : null}
          >
            {waitPack ? 'Обновление...' : 'Упаковать'}
          </Button>
          <div></div>
          <Input type="number" placeholder="Номер заявки ТМС" value={tmsnumber} onChange={handleTMSNumberChange} />
          <Button
            variant="contained"
            color="info"
            onClick={handleSend}
            disabled={waitSend || !tmsnumber}
            startIcon={waitSend ? <CircularProgress size={16} /> : null}
          >
            {waitSend ? 'Запись отправки...' : 'Отправить'}
          </Button>
        </Box>
      )}
      <SharedTable
        columns={columns}
        loading={isLoading}
        onFilterModelChange={handleDataQueryChange}
        onPaginationChange={handlePaginationChange}
        onSelectionChange={handleSelectionChange}
        onSortModelChange={handleDataQueryChange}
        rowCount={totalRows}
        rows={outbounds}
        selectedRowIds={selectedOutboundIds}
        tableName={tableName}
      />
    </div>
  );
};
