import { createSelector } from 'reselect';
import { RootState } from '../index';

const selectFromGanShipmentsState = (state: RootState) => state.fromGanShipmentsState;

export const selectFromGanShipments = createSelector(
  [selectFromGanShipmentsState],
  (fromGanShipmentsState) => fromGanShipmentsState.fromGanShipments
);

export const selectTotalRowsFromGanShipments = createSelector(
  [selectFromGanShipmentsState],
  (fromGanShipmentsState) => fromGanShipmentsState.totalRows
);

export const selectWaitFromGanShipmentsTableFetch = createSelector(
  [selectFromGanShipmentsState],
  (fromGanShipmentsState) => fromGanShipmentsState.waitTableFetch
);

export const selectWaitFromGanShipmentsPacking = createSelector(
  [selectFromGanShipmentsState],
  (fromGanShipmentsState) => fromGanShipmentsState.waitPacking
);

export const selectWaitFromGanShipmentsPackingError = createSelector(
  [selectFromGanShipmentsState],
  (fromGanShipmentsState) => fromGanShipmentsState.packingError
);

export const selectWaitFromGanShipmentsSending = createSelector(
  [selectFromGanShipmentsState],
  (fromGanShipmentsState) => fromGanShipmentsState.waitSending
);
export const selectWaitFromGanShipmentsSendingError = createSelector(
  [selectFromGanShipmentsState],
  (fromGanShipmentsState) => fromGanShipmentsState.sendingError
);
